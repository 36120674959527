$crowdin-primary-green-600: #43a047;
$crowdin-gray-005: rgba(38, 50, 56, 0.05);
$crowdin-gray-010: rgba(38, 50, 56, 0.1);
$crowdin-gray-012: rgba(38, 50, 56, 0.12);
$crowdin-gray-054: rgba(38, 50, 56, 0.54);
$crowdin-gray-087: rgba(38, 50, 56, 0.87);
$crowdin-body-bg: #f5f7f8;
$crowdin-dark: #263238;

$headings-color: $crowdin-dark;
$green: $crowdin-primary-green-600;
$white: #ffffff;
$border-color: $crowdin-gray-012;
$primary: $green;
$container-padding-x: 3rem;
$box-shadow-sm: 0px 1px 0px $crowdin-gray-012;
$font-family-base: "Noto Sans SC", sans-serif;
$body-bg: $crowdin-body-bg;
$body-color: $crowdin-gray-087;
$font-size-base: 0.875rem;
$h1-font-size: 2rem;
$h2-font-size: 1.5rem;
$headings-margin-bottom: 0.75rem;
$text-muted: $crowdin-gray-054;
$nav-link-color: $body-color;
$nav-link-hover-color: $body-color;
$card-border-color: $crowdin-gray-010;
$card-border-radius: 0.75rem;
$card-box-shadow: 0px 4px 8px $crowdin-gray-010;
$card-spacer-x: 1.5rem;
$card-spacer-y: 1.5rem;
$link-decoration: none;
$btn-padding-y: 0.625rem;
$btn-padding-x: 1rem;
$btn-border-radius: 0.5rem;
$form-label-font-weight: 500;
$form-label-margin-bottom: 0.25rem;
$input-font-size: 1rem;
// 0.5rem -1 px for border
$input-padding-y: 7px;
$input-padding-x: 0.75rem;
$input-border-radius: 0.5rem;
$input-bg: $white;
$btn-line-height: 1rem;
$input-border-color: $border-color;
$navbar-brand-margin-end: 2rem;
$toast-spacing: 0.5rem;
$toast-background-color: rgba(5, 12, 26, 0.87);
$toast-border-radius: 0.5rem;
$toast-color: $white;
$toast-padding-x: 1rem;
$card-bg: $white;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1240px,
  xxl: 1458px,
) !default;

@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-select/sass/bootstrap-select.scss";

//overrides
.btn {
  height: 40px;
  font-weight: 500;

  &-primary:disabled {
    color: $white;
  }

  &--icon-left {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }

  &-primary {
    color: $white;

    &:hover,
    &:focus,
    &:active {
      color: $white !important;
    }
  }

  &-outline-secondary {
    border-color: $border-color;
    color: $body-color;

    &:disabled {
      border-color: $border-color;
      color: $body-color;
    }

    &:hover,
    &:focus,
    &:active {
      color: $body-color !important;
      border-color: $border-color !important;
      background: $crowdin-gray-005 !important;
    }
  }
}

.toast-container {
  position: fixed !important;
  bottom: 0;

  .toast-body {
    cursor: default;
  }
}

.link-primary {
  cursor: pointer;
}

.card {
  &--thin {
    .card-body {
      padding: 1.5rem 1rem;
    }
  }
  &--active {
    box-shadow: var(--bs-card-box-shadow);
  }
  &--borderless {
    border: none;
  }
  &--disabled {
    .card-body {
      opacity: 0.54;
    }

    .bootstrap-select {
      pointer-events: none;

      .dropdown-toggle .filter-option-inner-inner {
        color: $input-disabled-color;
      }

      button {
        background-color: $input-disabled-bg;
        border-color: $input-disabled-border-color;
      }
    }
  }
}

.text-muted {
  color: $text-muted !important;
}

// bootstrap select overrides
.bootstrap-select {
  width: 100% !important;

  .bs-searchbox {
    margin-bottom: 0.5rem;
  }

  .no-results {
    background: $white;
    text-align: center;
    color: $text-muted;
  }

  .dropdown-toggle {
    background: $white;
    border-color: $border-color;
    padding: $input-padding-y $input-padding-x;
    font-size: $input-font-size;
    line-height: 1.5rem;
    font-weight: $input-font-weight;

    &:active {
      background: $white;
      background: $input-focus-bg;
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }

    &:focus {
      outline: none !important;
    }

    .filter-option {
      overflow-y: initial;
    }

    .filter-option-inner-inner {
      overflow-y: unset;
      text-overflow: ellipsis;
      color: $body-color;
    }
  }
}

// utiltity classes
.fw-500 {
  font-weight: 500;
}
.text-small {
  font-size: 0.75rem;
}
.text-extra-small {
  font-size: 0.625rem;
}

.help-text {
  display: inline-block;
  margin-top: 0.25rem;
}

// custom styles
.wizard-wrapper {
  min-height: 100vh;
}

.wizard-title {
  margin-top: 4rem;
}

.wizard-subtitle {
  color: $headings-color;
  margin-bottom: 2rem;
}

.wizard-card-text {
  color: $headings-color;
}

.wizard-entity-wrapper {
  display: flex;
  padding: $spacer;
  align-items: center;
  background: $body-bg;
  border-radius: $btn-border-radius;
}

.wizard-user-btn {
  height: 40px;
  display: flex;
  font-weight: 500;
  background: $white;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: $btn-border-radius;
  padding: $btn-padding-y $btn-padding-x;
  white-space: nowrap;
  overflow: hidden;

  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .wizard-avatar-loader,
  img {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    margin-right: $spacer * 0.5;
  }

  .wizard-avatar-loader {
    background: $border-color;
  }

  .wizard-text-loader {
    height: 22px;
    width: 60px;
    background: $border-color;
  }
}

.wizard-testimonial {
  font-family: "Noto Sans", sans-serif;

  &__logo {
    width: 80px;
  }
}

.wizard-stepper {
  margin-bottom: 0;

  &__mark {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -2px;
    z-index: 1;
    background: white;
  }

  &__step {
    display: flex;
    align-items: center;
    position: relative;

    &--last {
      .wizard-stepper__text {
        padding-bottom: 0;
        border: none;
      }
    }
  }

  &__text {
    font-size: 0.75rem;
    font-weight: 500;
    color: $text-muted;
    position: relative;
    left: 10.5px;
    padding-left: 19.5px;
    border-left: 1px solid $border-color;
    padding-bottom: 1rem;
    cursor: default;

    &--active {
      color: $body-color;
    }

    &--with-border {
      border-left: 1px solid $crowdin-primary-green-600;
    }
  }
}

.wizard-preloader {
  height: 100vh;
  position: relative;
}

.wizard-app-loader {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}
